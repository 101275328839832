import { PropsWithChildren, useEffect } from 'react';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';

import WordmarkSrc from '@/assets/wordmark.svg';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { useIsMobileOrTabletViewport } from '@/hooks/useIsMobileViewport';

import {
  Background,
  BackgroundBackdrop,
  Banner,
  CloseButton,
  Container,
  ContentMain,
  ContentRoot,
  Dialog,
  Foreground,
  Header,
  HeaderLink,
  HeaderWordmark,
  MobileCloseButton,
  NextButton,
  PrevButton,
  StoryPlayerWrapper,
} from './StoryDialog.styles';

type StoryViewDialogProps = PropsWithChildren<{
  open: boolean;
  isAnonymous?: boolean;
  showClose?: boolean;
  onPrevious?: (() => void) | null;
  onNext?: (() => void) | null;
  onSignIn?: () => void;
  onClose?: () => void;
}>;

export function StoryDialog({
  open,
  isAnonymous = false,
  showClose = true,
  onPrevious,
  onNext,
  onSignIn,
  onClose,
  children,
}: StoryViewDialogProps) {
  const isMobileOrTablet = useIsMobileOrTabletViewport();

  useEffect(() => {
    if (!showClose || open === false) {
      return;
    }

    // Close modal on esc
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose?.();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [onClose, open, showClose]);

  return (
    <AnimatePresence>
      {open && (
        <Dialog>
          <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
          >
            <Background>
              <BackgroundBackdrop />
            </Background>

            <Foreground>
              {isAnonymous && (
                <>
                  <Header>
                    <HeaderWordmark src={WordmarkSrc} />
                    <HeaderLink type="sans" size="xs" color="inverse-on-surface-primary" bold onClick={onSignIn}>
                      Log in
                    </HeaderLink>
                  </Header>
                  <Banner href="https://remento.co" target="_blank">
                    <RMText type="sans" size="s" color="on-surface-primary">
                      Capture your own family stories
                    </RMText>
                  </Banner>
                </>
              )}
              <ContentRoot data-banner={isAnonymous}>
                <ContentMain>
                  <StoryPlayerWrapper>{children}</StoryPlayerWrapper>
                </ContentMain>

                {showClose && isMobileOrTablet == true && (
                  <MobileCloseButton tooltip={null} icon={faChevronLeft} outline onClick={onClose} />
                )}
                {showClose && isMobileOrTablet == false && (
                  <CloseButton onClick={onClose}>
                    <RMTooltip message="Close" side="left">
                      <FontAwesomeIcon size="2x" icon={faClose} color="var(--inverse-on-surface-primary)" />
                    </RMTooltip>
                  </CloseButton>
                )}

                {onPrevious && isMobileOrTablet == false && (
                  <PrevButton
                    icon={faChevronLeft}
                    tooltip={{ label: 'Previous story', position: 'right' }}
                    onClick={onPrevious}
                  />
                )}
                {onNext && isMobileOrTablet == false && (
                  <NextButton
                    icon={faChevronRight}
                    tooltip={{ label: 'Next story', position: 'left' }}
                    onClick={onNext}
                  />
                )}
              </ContentRoot>
            </Foreground>
          </Container>
        </Dialog>
      )}
    </AnimatePresence>
  );
}
