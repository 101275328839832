import { faCompress, faExpand, faPause, faPlay, faVolume, faVolumeXmark } from '@fortawesome/pro-solid-svg-icons';
import { LinariaClassName } from '@linaria/core';
import { MuteButton, PlayButton, TimeSlider, useMediaState, useMediaStore } from '@vidstack/react';
import clsx from 'clsx';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { useIsFullscreen } from '@/hooks/useIsFullscreen';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { useToggleFullscreen } from './hooks/useFullscreen';
import {
  Container,
  Controls,
  ControlsSection,
  DotSeparator,
  PromptQuestionText,
  Times,
  TimeSliderProgress,
  TimeSliderRoot,
  TimeSliderThumb,
  TimeSliderTrackFill,
  timeSliderTrackStyles,
} from './PlayerScrubber.styles';

interface PlayerScrubberProps {
  promptText: string;
  trackStyles?: LinariaClassName | null;
  hideThumb?: boolean;
}

function formatTime(seconds: number) {
  const date = new Date(seconds * 1000);
  const formatter = new Intl.DateTimeFormat('en', { minute: '2-digit', second: '2-digit' });
  return formatter.format(date);
}

function Play() {
  const isPaused = useMediaState('paused');
  const isMobile = useIsMobileViewport();

  return (
    <PlayButton onClick={(event) => event.stopPropagation()}>
      <RMIconButton
        icon={isPaused ? faPlay : faPause}
        tooltip={{ label: isPaused ? 'Play' : 'Pause', position: 'top' }}
        backgroundColor="transparent"
        color="white"
        as="div"
        size={isMobile ? 'xs' : 'lg'}
        iconSize="lg"
      />
    </PlayButton>
  );
}

function Volume() {
  const isMuted = useMediaState('muted');
  const isMobile = useIsMobileViewport();

  return (
    <MuteButton onClick={(event) => event.stopPropagation()}>
      <RMIconButton
        icon={isMuted ? faVolumeXmark : faVolume}
        tooltip={{ label: isMuted ? 'Unmute' : 'Mute', position: 'top' }}
        backgroundColor="transparent"
        color="white"
        as="div"
        size={isMobile ? 'xs' : 'lg'}
        iconSize="lg"
      />
    </MuteButton>
  );
}

function Fullscreen() {
  const isFullscreen = useIsFullscreen();
  const isMobile = useIsMobileViewport();
  const toggleFullscreen = useToggleFullscreen();

  return (
    <RMIconButton
      icon={isFullscreen ? faCompress : faExpand}
      tooltip={{ label: isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen', position: 'top' }}
      backgroundColor="transparent"
      color="white"
      as="div"
      size={isMobile ? 'xs' : 'lg'}
      iconSize="lg"
      onClick={(e) => {
        // This will prevent from clicking on other elements behind this button
        // when exiting the fullscreen mode.
        e.preventDefault();
        e.stopPropagation();

        toggleFullscreen();
      }}
    />
  );
}

export function PlayerScrubber({ promptText, hideThumb = false, trackStyles = null }: PlayerScrubberProps) {
  const isMobile = useIsMobileViewport();
  const { currentTime, duration } = useMediaStore();
  const progressionPerc = (currentTime * 100) / duration;

  return (
    <Container>
      <TimeSliderRoot className="time-slider" onClick={(event) => event.stopPropagation()}>
        <TimeSlider.Track className={clsx('track', trackStyles ?? timeSliderTrackStyles)} />
        <TimeSliderTrackFill
          style={{ width: `${progressionPerc}%` }}
          className={trackStyles ?? timeSliderTrackStyles}
        />
        <TimeSliderProgress className={trackStyles ?? timeSliderTrackStyles} />
        {!hideThumb && <TimeSliderThumb className="thumb" />}
      </TimeSliderRoot>

      <Controls>
        <ControlsSection>
          {isMobile == false && <Play />}
          {isMobile == false && <Volume />}

          <Times type="sans" align="right" size="xs" color="inverse-on-surface-primary">
            {formatTime(currentTime)} / {formatTime(duration)}
          </Times>

          {promptText && (
            <DotSeparator type="sans" size="xxs" color="inverse-on-surface-primary">
              •
            </DotSeparator>
          )}

          <PromptQuestionText truncate type="sans" size="xs" color="inverse-on-surface-primary">
            {promptText}
          </PromptQuestionText>

          <Fullscreen />
        </ControlsSection>
      </Controls>
    </Container>
  );
}
