import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';

export const StyledStorySummary = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;

  body[data-mobile='false'][data-tablet='false'] & {
    max-width: 32rem;
    min-width: 32rem;
    height: 100%;
    /* This is used to prevent the story regenerate animation from clipping outside the dialog */
    overflow-y: hidden;
  }

  body[data-fullscreen='true'] & {
    display: none;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: var(--surface);

  body[data-mobile='false'][data-tablet='false'] & {
    border-top-right-radius: var(--radius-smooth);
    border-bottom-right-radius: var(--radius-smooth);
  }
`;

export const ScrollArea = styled.div`
  width: 100%;
  padding: var(--spacing-xl);
  flex-grow: 1;

  body[data-mobile='false'][data-tablet='false'] & {
    overflow-y: auto;
  }

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    padding: var(--spacing-xl) var(--spacing-xs);
  }
`;

export const titleInputClass = css`
  font-family: var(--font-serif);
  font-size: 2rem;
  line-height: 140%;
  color: var(--on-surface-primary);
  border: 1px solid transparent;
  padding: var(--spacing-sm);
  border-radius: var(--radius-round);
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
  cursor: text;

  &[contentEditable='true'] {
    border-color: var(--border-strong);
    background-color: var(--surface);

    :hover {
      border-color: var(--border-stronger);
    }

    :focus {
      // this padding prevent the component change content position because of the bolder border
      padding: calc(var(--spacing-sm) - 1px);
      border-width: 2px;
      border-color: var(--border-primary);
    }
  }

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    font-size: 1.5625rem;
    padding: var(--spacing-xs);

    :focus {
      // this padding prevent the component change content position because of the bolder border
      padding: calc(var(--spacing-xs) - 1px);
    }
  }
`;

export const summaryInputClass = css`
  font-family: var(--font-serif);
  font-size: 1.25rem;
  line-height: 140%;
  color: var(--on-surface-primary);
  border: 1px solid transparent;
  padding: var(--spacing-sm);
  border-radius: var(--radius-round);
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  min-height: 8rem;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
  cursor: text;

  p {
    margin-bottom: 1rem;
  }

  &[contentEditable='true'] {
    border-color: var(--border-strong);
    background-color: var(--surface);

    :hover {
      border-color: var(--border-stronger);
    }

    :focus {
      // this padding prevent the component change content position because of the bolder border
      padding: calc(var(--spacing-sm) - 1px);
      border-width: 2px;
      border-color: var(--border-primary);
    }
  }

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    font-size: 1.125rem;
    padding: var(--spacing-xs);

    :focus {
      // this padding prevent the component change content position because of the bolder border
      padding: calc(var(--spacing-xs) - 1px);
    }
  }
`;

export const StoryActionsWrapper = styled.div`
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    position: fixed;

    &[data-editing='true'] {
      top: 0;
      bottom: unset;
      z-index: 10;
    }
  }
`;

export const StoryIssuesWrapper = styled.div`
  position: absolute;
  bottom: calc(5rem + 1px);
  left: 0;
  width: 100%;
  height: 2.5rem;
  background-color: var(--surface-bright);
  border-top: 1px solid var(--border-weak);
`;

export const StoryIssues = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: 0 var(--spacing-xl);
  width: 100%;
  height: 100%;
  border-left: 4px solid var(--caution);
  background-color: hsla(20, 76%, 45%, 0.1);
`;

export const ViewStoryIssuesButton = styled.button`
  margin-left: auto;
  cursor: pointer;
  text-decoration: underline;
  color: var(--caution);
`;

export const LearnMoreButton = styled(RMButton)`
  height: 8rem;
  margin-top: var(--spacing-2xl);
  width: 100%;
  z-index: 0;
`;

export const LearnMoreButtonImage = styled.img`
  height: 106px !important;
  width: auto !important;
  margin-right: 24px;
`;
